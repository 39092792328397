import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"


export default function PostHeader () {
  const data = useStaticQuery(graphql`
  query MyQuery {
    file(relativePath: {eq: "final_cover.png"}) {
      id
      childImageSharp {
        fluid(maxWidth: 150) {
          src
        }
      }
    }
  }
  `)

  return (
      <Link to="/">
        <header className="flex flex-row items-center w-full h-12 mt-6 md:mt-4 mb-4 md:mb-0.5 md:h-20 pt-1 pb-0.5 border-black border-b">
            <img 
                src={data.file.childImageSharp.fluid.src} 
                alt="logo"
                className="h-full md:h-5/6 ml-4 md:ml-10"   
            />
            <h3 className='font-display ml-4 mr-0.5 text-lg justify-self-center leading-tight'>The Narrative Monopoly Podcast</h3>
        </header>
      </Link>
  )
}