import React from 'react'
import { graphql } from 'gatsby'
// import DOMpurify from 'dompurify'
// import Final from "../images/final_cover.png"
import '../styles/global.css'
import PostHeader from '../components/postHeader'


export default function Post ({ data: { prismicPost }}) {
    const {data} = prismicPost
    // const cleanBody = DOMpurify.sanitize(data.body.html);
    // console.log(`DIRTY: ${data.body.html}`)
    // console.log(`CLEAN: ${cleanBody}`)
    return (
        <React.Fragment>
           <PostHeader />
            <div className="post pl-8 pr-8 md:mx-32 lg:mx-52 xl:mx-80 mt-4">
                <h1>{data.title.text}</h1>
                <h3>{data.subtitle.text}</h3>
                <div dangerouslySetInnerHTML={{ __html: data.body.html}} />
                {/* took out cleanBody above, can i get pics this way? */}
            </div>
          <footer className="h-8 w-full border-t-2"></footer>
        </React.Fragment>
        
    )
}

export const pageQuery = graphql`
    query PostBySlug($uid: String!) {
        prismicPost(uid: { eq: $uid }) {
            uid
            data {
                title {
                    text
                }
                subtitle {
                    text
                }
                body {
                    html
                }
            }
        }
    }
`
